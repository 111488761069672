/* Smooth width transition for sidebar */
.sidebar-container {
  /* transition: width 0.3s ease; */
  overflow: hidden;
}

/* Animation for opening the sidebar */
@keyframes openSidebar {
  0% {
    width: 112px;
    transform: scale(0.8);
  }

  100% {
    width: 360px;
    transform: scale(1);
  }
}

/* Animation for closing the sidebar */
@keyframes closeSidebar {
  0% {
    width: 360px;
    transform: scale(1.03);

  }
  100% {
    width: 112px;
    transform: scale(1);
  }
}

/* Apply animations for opening and closing */
.open {
  animation: openSidebar 0.3s forwards ease-out;
  width: 360px; /* Final width when open */
}

.closed {
  animation: closeSidebar 0.3s forwards ease-in;
  width: 112px; /* Final width when closed */
}

/* when Side bar closing */
.sidebar-closing-content {
  animation: sideBarContentClose 0.3s ease-in forwards;
  opacity: 0; /* Final opacity when close 0 */
}

@keyframes sideBarContentClose {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

/* Sidebar text opening animation */
.sidebar-opening-content {
  animation: sideBarContentOpen 0.3s ease-out forwards;
}

/* Keyframes for smooth scaling up */
@keyframes sideBarContentOpen {
  0% {
    opacity: 0;
    transform: scale(0.8); /* Start smaller */
  }
  50% {
    opacity: 1;
    transform: scale(1); /* End at normal size */
  }
  100% {
    opacity: 0.8;
    transform: scale(0.8); /* End at normal size */
  }
}

.sidebar-open {
  display: flex;

}

.sidebar-close {
  display: none;
}

/* Logo button styling */
.logo-button {
  cursor: pointer;
  padding: 8px;
}
