/* src/components/Modal.css */

/* Center the modal on the screen */
.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

/* Modal content with smooth pop-up animation */
.modal-content {
  background: white;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation: popUp 0.3s forwards;
}

/* Pop-up animation when opening */
@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Fade-out and scale-down animation when closing */
.modal-content.closing {
  animation: popDown 0.3s forwards;
}

@keyframes popDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

/* Modal header */
.modal-content h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

/* Confirmation text */
.modal-content p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

/* Modal buttons */
.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.cancel-btn {
  padding: 12px 20px;
  background-color: #6c757d;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cancel-btn:hover {
  background-color: #5a6268;
}

.confirm-btn {
  padding: 12px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.confirm-btn:hover {
  background-color: #218838;
}
