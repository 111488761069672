/* Tailwind config */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700&family=Sprat:wght@400;700&display=swap');
@import 'react-toastify/dist/ReactToastify.css';




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Ultra thin transparent scrollbar */


/* Ultra thin transparent scrollbar - vertical */
::-webkit-scrollbar {
  width: 4px; /* Vertical scrollbar width */
  height: 4px; /* Horizontal scrollbar height */
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1); /* Transparent track */
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); /* Light transparent thumb */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4); /* Darker on hover */
}

/* Firefox and other browsers */
.scrollable {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) rgba(255, 255, 255, 0.1);
}

/* Horizontal scrollbar specific styling */
::-webkit-scrollbar:horizontal {
  height: 4px; /* Horizontal scrollbar height */
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.2); /* Horizontal thumb styling */
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background: rgba(0, 0, 0, 0.4); /* Darker on hover */
}
