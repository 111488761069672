.numberpad-containernewui {
    position: relative;
}
.numberpad-containernewui::after { 
    content: ''; /* No text content */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translateY(-50%);
    top: -0%;
    left: -5%;
    width: 450px; /* Adjust width to match the image size */
    height: 450px; /* Adjust height to match the image size */
    background-color: transparent; /* Optional, remove the background color if needed */
    background-image: url('../../../assets/Icons/RippleImage1.png'); /* Replace with the actual path to your image */
    background-size: cover; /* Ensure the image covers the area */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    border-radius: 50%; /* Optional, for rounded images */
    cursor: pointer;
    z-index: 20;
}
.after-bottom-right {
    position: absolute;
    bottom: -44%; /* Adjust to move the image up or down */
    left: 60%; /* Adjust to move the image left or right */
    width: 450px; 
    height: 500px;
    background-color: transparent; /* Optional, remove the background color if needed */
    background-image: url('../../../assets/Icons/HandPointingLeftSmall.png'); /* Replace with the second image */
    background-size: contain; /* Scale the image to fit within the area */
    background-position: center; /* Center the image */
    background-repeat: no-repeat;
    border-radius: 50%; /* Optional, for rounded images */
    cursor: pointer;
    z-index: 40;
}
  


/* Media Queries */

/* sm: 640px */
@media (min-width: 640px) {
    .numberpad-containernewui::after {
        top: 4%;
        left: -58%;
        width: 360px; 
        height: 360px; 
    }
    .after-bottom-right{
        visibility: hidden;
    }
}

/* md: 768px */
@media (min-width: 768px) {
    .numberpad-containernewui::after {
        /* top: 15%;
        left: 10%; */
    }
    .after-bottom-right{
        visibility: hidden;
    }
   
}

/* lg: 1024px */
@media (min-width: 1024px) {
    .numberpad-containernewui::after {
        top: 4%;
        left: -58%;
        width: 550px; 
        height: 550px; 
    }

    .after-bottom-right{
        visibility: visible;
    }
   
}

/* xl: 1280px */
@media (min-width: 1280px) {
    .numberpad-containernewui::after {
        /* top: 16%;
        left: -4%;
        width: 450px;
        height: 450px;  */
    }
}

/* 2xl: 1536px */
@media (min-width: 1536px) {
    .numberpad-containernewui::after {
        /* top: 24%;
        left: 8%; 
        width: 580px;
        height: 580px;  */
    }
    .after-bottom-right{
        bottom: -32%; /* Adjust to move the image up or down */
        left: 64%; /* Adjust to move the image left or right */
        width: 550px; 
        height: 608px;
        visibility: visible;

    }
}


.tooltipnewui {
    background-color: #E9D7FE; /* Adjust color to match the green shade */
    color: #42307D;
    font-weight: bold;
    padding: 16px;
    border-radius: 12px;
    font-size: 14px;
    width: 250px;
    text-align: center;
    position: absolute;
    bottom: 60%;
    font-size: 20px;
    left: 20%;
    z-index: 40;
    opacity: 1;
    transition: opacity 0.3s ease;
  }


  .tooltipnewui::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 60%; /* Positioned at the bottom of the tooltip */
    left: -24%;
    margin-left: 48px;
    /* border-width: 20px; */
    border-left-width: 25px;
    /* border-right-width: 20px; */
    z-index: 0;
    border-top-width: 25px;
    /* border-bottom-width: 20px; */
    border-style: solid;
    border-color: #E9D7FE transparent transparent transparent; /* Green arrow pointing down */
    transform: rotate(90deg); /* Slight rotation */
  }
  