@keyframes openExpansion {
  0% {
    opacity: 0;
    transform: scale(0.95); /* Start smaller */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* End at normal size */
  }
}

@keyframes closeExpansion {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}

.toggle-expansion-opening {
  animation: openExpansion 0.4s ease-in-out forwards; /* Smooth in and out */
}

.toggle-expansion-closing {
  animation: closeExpansion 0.4s ease-in-out forwards;
}

.toggleopen {
  display: flex;
  flex-direction: column;
}

.toggleclose {
  display: none;
}
