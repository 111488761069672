@keyframes blink {
  0% {
    transform: scale(1);
    color: #4299e1;
  }
  50% {
    transform: scale(1.1);
    color: #63b3ed;
  }
  100% {
    transform: scale(1);
    color: #4299e1;
  }
}

.animate-blink {
  animation: blink 2.5s ease-in-out infinite;
}

@keyframes blinkNormal {
  0% {
    transform: scale(1);
    color: #4299e1;
  }
  25% {
    color: #5aabeb;
  }
  50% {
    transform: scale(1.1);
    color: #63b3ed;
  }
  75% {
    color: #5aabeb;
  }
  100% {
    transform: scale(1);
    color: #4299e1;
  }
}

.animate-blink-normal {
  animation: blinkNormal 2.5s ease-in-out infinite;
}
