/* Animation for scaling up and down to simulate sound */
.sound-icon-animate {
  animation: scalePulse 2s infinite ease-in-out;
}

/* Abort Icon animation */
.abort-icon-animate {
  animation: scalePulse 2s infinite ease-in-out;
}

.pulse-icon-animate {
  animation: scalePulse 2s infinite ease-in-out;
}

@keyframes scalePulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.pressed {
  transform: scale(0.95);
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ); /* Slightly darkened background on press */
  transition: transform 0.1s ease, background-color 0.1s ease;
}
/* Tool tip config */
.tooltip-container {
  /* position: relative;
  display: inline-block;
  cursor: pointer; */
}
.tooltip {
  background-color: #12b76a; /* Adjust color to match the green shade */
  color: black;
  font-weight: bold;
  padding: 16px;
  border-radius: 12px;
  font-size: 14px;
  width: 250px;
  text-align: center;
  position: absolute;
  bottom: 60%;
  font-size: 20px;
  /* Adjust to position above or below the element */
  left: 20%;
  /* transform: translateX(-150%); */
  z-index: 40;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.tooltip-container:hover .tooltip {
  opacity: 1;
}
.tooltip::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 58%; /* Positioned at the bottom of the tooltip */
  left: -24%;
  margin-left: 48px;
  /* border-width: 20px; */
  border-left-width: 25px;
  /* border-right-width: 20px; */
  z-index: 0;
  border-top-width: 25px;
  /* border-bottom-width: 20px; */
  border-style: solid;
  border-color: #12b76a transparent transparent transparent; /* Green arrow pointing down */
  transform: rotate(90deg); /* Slight rotation */
}
