/* SliderAudiogram.css */

/* Style the slider track */
input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%;
    background: transparent; /* Otherwise white in Chrome */
  }
  
  /* Custom track */
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 20px;
    background: #ddd;
    border-radius: 10px;
    cursor: pointer;
  }
  
  /* Custom thumb */
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: blue;
    margin-top: -15px; /* Centers the thumb on the track */
    cursor: pointer;
  }
  
  /* For Firefox */
  input[type='range']::-moz-range-thumb {
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: blue;
    cursor: pointer;
  }
  
  /* For IE */
  input[type='range']::-ms-thumb {
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: blue;
    cursor: pointer;
  }
  
  /* Adjust track for Firefox */
  input[type='range']::-moz-range-track {
    width: 100%;
    height: 20px;
    background: #ddd;
    border-radius: 10px;
    cursor: pointer;
  }
  
  /* Adjust track for IE */
  input[type='range']::-ms-track {
    width: 100%;
    height: 20px;
    background: transparent; /* Removes default grey background */
    border-color: transparent;
    color: transparent;
  }
  