.after_or_beforecontent {
  position: relative;
  display: inline-block;
  margin-top: 0;
}

.after_or_beforecontent::after {
  content: "";
  position: absolute;
  top: -225px;
  left: -240px;
  width: 500px;
  height: 500px;
  background-image: url('../../../../src/assets/Icons/RippleImage.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%; /* Optional: rounds the background */
}
