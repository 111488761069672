/* Bouncing effect for Kela card with slight rotation */
.bouncing-div-kela {
  animation: bounce 8s infinite ease-in-out;
}

/* Cloud Test Styling */
.cloud-test {
  min-width: 365px;
  max-width: none;
}

/* Enforce min-width and width based on screen size */
.enforce-cloud {
  background-color: #FFFFFF;
}


@keyframes bounce {
  0%,
  100% {
    transform: translateY(100px) rotate(18deg) scale(1);
  }
  50% {
    transform: translateY(-200px) rotate(0deg) scale(1.1);
  }
}


/* Cloud animation - subtle movement */
/* The Gaps are fixed here */
@keyframes cloudMove {
  0% {
    transform: translateX(40%) translateY(-50%) rotate(-30deg);
  }
  50% {
    transform: translateX(45%) translateY(-52%) rotate(-25deg);
  }
  100% {
    transform: translateX(40%) translateY(-50%) rotate(-30deg);
  }
}

.cloud {
  animation: cloudMove 12s ease-in-out infinite;
  right: 0%;
}

/* Styles for small screens (like mobile phones) */
@media (max-width: 839px) {
  .cloud {
    display: none;
  }
  .cloud-bg {
    display: none;
  }
}

/* Styles for laptops with screen height up to 1030px */
@media (min-width: 800px) and (max-width: 1906px) and (max-height: 1030px) {
  .enforce-cloud {
    min-width: 260px;
    width: 260px;
  }
}

/* Styles for monitors with screen height between 1031px and 1800px */
@media (min-width: 1031px) and (max-width: 2400px) and (min-height: 1031px) and (max-height: 1800px) {
  .enforce-cloud {
    min-width: 305px;
    width: 305px;
  }
  .cloud {
    width: 40vw;
    right: 0%;
    transform: translateX(20%) translateY(-50%) rotate(-15deg);
  }
}

/* Additional Styles for screens with min width 1359px and min height 1564px */
@media (min-width: 1359px) and (min-height: 1564px) {
  .enforce-cloud {
    min-width: 360px;
    width: 360px;
  }
  .cloud {
    width: 40vw;
    right: 0%;
    transform: translateX(20%) translateY(-50%) rotate(-15deg);
  }
}

/* Specific styles for 1920x1009 screen resolution */
@media (min-width: 1920px) and (max-width: 1920px) and (min-height: 1009px) and (max-height: 1009px) {
  .enforce-cloud {
    min-width: 280px; /* Adjust width to prevent gaps */
    width: 280px;
  }

  .cloud {
    width: 45vw; /* Increase cloud width to fill the space */
    right: -5%; /* Adjust positioning to prevent gaps */
    transform: translateX(10%) translateY(-50%) rotate(-15deg);
  }
}


/* Adjust min-width when the screen width exceeds 2386px */
@media (min-width: 2387px) {
  .enforce-cloud {
    min-width: 240px;
    width: 360px; 
  }
  .cloud {
    width: 40vw;
    right: 0%;
    transform: translateX(50%) translateY(-50%) rotate(-30deg);
  }
}

/* General styles for very large screens (e.g., large monitors or TVs) */
@media (min-width: 3000px) {
  .enforce-cloud {
    min-width: 200px; /* Further decrease min-width for extremely large screens */
    width: 18vw; /* Use percentage to scale with screen size */
  }
}