.sign-out-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}

.sign-out-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 500px; /* Adjust width as needed */
    text-align: center;
}

.sign-out-modal-text {
    margin-bottom: 20px;
}

.sign-out-modal-actions {
    display: flex;
    justify-content: space-around;
}

.sign-out-modal-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

.sign-out-modal-button.sign-out {
    background-color: #d9534f; /* Bootstrap 'btn-danger' color */
    color: #fff;
}

.sign-out-modal-button.cancel {
    background-color: #f0ad4e; /* Bootstrap 'btn-warning' color */
    color: #fff;
}