.numberpad-container {
  position: relative;
}

/* Styling for Next Button */
.numberpad-container::after {
  content: attr(data-next-text);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -250px;
  top: 50%;
  transform: translateY(-50%);
  padding: 3.5rem 2.5rem;
  background-color: #8e24aa;
  color: #f5f5f5;
  border-radius: 1.25rem;
  font-size: 1.5rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  /* pointer-events: auto; */
  pointer-events: none; /* Make sure the pseudo-element doesn't interfere */
  z-index: 20;
}

/* Hover effect only if not disabled */
.numberpad-container:not([data-disabled="true"]):hover::after {
  background-color: #6a1b9a;
  transform: translateY(-50%) scale(1.05);
}

/* Disabled styling */
.numberpad-container[data-disabled="true"]::after {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
  pointer-events: none;
}

/* Invisible overlay button that captures clicks */
.next-button-overlay {
  position: absolute;
  right: -250px;
  top: 50%;
  transform: translateY(-50%);
  width: 200px; /* Match the visual size of the ::after element */
  height: 200px; /* Match the visual height of the ::after element */
  
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 20;
  /* border: 1px solid green; */
}

.next-button-overlay:disabled {
  cursor: not-allowed;
}
